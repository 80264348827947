import confetti from 'canvas-confetti'

document.addEventListener('DOMContentLoaded', function () {
  const body = document.getElementsByClassName('is-mobile daily_happy_lots-show')[0];
  if(!body) { return }

  const drawBtn = document.querySelector('[data-draw-btn]');
  const pageCurtain = document.querySelector('[data-page-curtain]');
  if(!drawBtn || !pageCurtain) { return }

  drawBtn.addEventListener('click', () => {
    drawBtn.disabled = true
    pageCurtain.dataset.pageCurtain = 'close'
    const linkTo = pageCurtain.dataset.pageUrl
    setTimeout(() => {
      body.classList.add('bodyfadeout')
    }, 400)
    setTimeout(() => {
      location.href = linkTo
    }, 1000)
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const isWinnerPage= !!(document.querySelector('[data-daily-happy-lot-page="winner"]'));
  if(!isWinnerPage) { return }

  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
    ticks: 1000,
    colors: [
      '#FF6950',
      '#80C699',
      '#8FD2E6',
      '#F7EA8F',
      '#F3B0CD',
      '#F7C288'
    ]
  });
});
